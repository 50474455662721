/*font families */
.font-light {
  font-family: "Poppins-Light", sans-serif;
}
.font-regular {
  font-family: "Poppins-Regular", sans-serif;
}
.font-semibold {
  font-family: "Poppins-SemiBold", sans-serif;
}
.font-bold {
  font-family: "Poppins-Bold", sans-serif;
}
.font-black {
  font-family: "Poppins-Black", sans-serif;
}
/*font families */

/* font sizes */
.display-1 {
  font-size: 72px;
  line-height: 80px;
}
.display-2 {
  font-size: 60px;
  line-height: 72px;
}
.h1 {
  font-size: 48px;
  line-height: 56px;
}
.h1-small {
  font-size: 34px;
  line-height: 40px;
}
.h2 {
  font-size: 39px;
  line-height: 47px;
}
.h3,
.h2-small {
  font-size: 33px;
  line-height: 40px;
}
.h4,
.h3-small {
  font-size: 28px;
  line-height: 34px;
}
.h5,
.h4-small {
  font-size: 23px;
  line-height: 28px;
}
.h5-small,
.h6 {
  font-size: 19px;
  line-height: 23px;
}
.h6-small,
.p1 {
  font-size: 14px;
  line-height: 20px;
}
.subheading {
  font-size: 20px;
  line-height: 28px;
}
.p2 {
  font-size: 16px;
  line-height: 24px;
}
.p3 {
  font-size: 18px;
  line-height: 22px;
}
.caption {
  font-size: 12px;
  line-height: 18px;
}
.footer-cap {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
}
/* font sizes */

/* text colors */
.color-secondary500{
  color: $secondary-500;
}
.color-gray400{
  color: $gray-400;
}
.color-gray500 {
  color: $gray-500;
}
.color-gray600 {
  color: $gray-600;
}
.color-gray700 {
  color: $gray-700
}
.color-gray900 {
  color: $gray-900;
}
.color-success500{
  color: var(--success-500);
}
.color-black{
  color: $black;
}
/* text colors */

/*text transform*/
.uppercase{
    text-transform: uppercase;
}
/*text transform*/

.letter-spacing-1{
  letter-spacing: 1px;
}
.underline{
  text-decoration: underline;
}


/* link styles */
.force-link-disabled,
.force-link-disabled:hover,
.force-link-disabled:focus{
  color: var(--gray-600) !important;
  text-decoration: none !important;
}
.force-blue-link,
.force-blue-link:hover,
.force-blue-link:focus{
  color: var(--secondary-600) !important;
}
/* link styles */
