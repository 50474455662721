.cta {
  font: 14px/17px 'Poppins-SemiBold', sans-serif;
  padding: 12px 18px;
  border-radius: 24px;
  background-color: $gray-200;
  color: $gray-400;
  position: relative;
  &.cta-sm {
    font-size: 12px;
    padding: 8px 12px;
  }
  &.cta-lg {
    font-size: 18px;
    padding: 14px 20px;
  }
  &.cta-secondary {
    background-color: $success-600;
    color: $white;
    &:hover {
        background-color: $success-700;
    }
    &:active{
        background-color: $success-800;
    }
  }
  &.cta-blue {
    background-color: $secondary-500;
    color: $white;
    &:hover {
        background-color: $secondary-700;
    }
    &:active{
        background-color: $secondary-800;
    }
  }
  &.cta-outline{
    background-color: transparent;
    border: 1px solid $gray-600;

    &.cta-outline-dark{
      color: $gray-900;
      border-color: $gray-900;
      &:hover {
          background-color: $gray-100;
      }
      &:active{
          background-color: $gray-200;
      }
    }
    &.cta-outline-blue{
      color: $secondary-500;
      border-color: $secondary-500;
      &:hover {
          background-color: $secondary-100;
      }
      &:active{
          background-color: $secondary-200;
      }
    }

  }
  .cta-icon{
    position: absolute;
    top: 9px;
    left: 16px;
    width: 14px;
  }
}
.br30{
  border-radius: 30px;
}
