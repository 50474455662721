@font-face {
  font-family: 'Poppins-Light';
  src: url('/assets/fonts/Poppins-Light.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
    font-family: 'Poppins-Regular';
    src: url("/assets/fonts/Poppins-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('/assets/fonts/Poppins-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('/assets/fonts/Poppins-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Black';
    src: url('/assets/fonts/Poppins-Black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}