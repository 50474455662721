/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import "~swiper/css/lazy";
@import "~swiper/css/pagination";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/fonts.scss";
@import "theme/colors.scss";
@import "theme/typography.scss";
@import "theme/utilities.scss";
@import "theme/checkbox.scss";
@import "theme/buttons.scss";
@import "theme/mixins.scss";

* {
  font-family: 'Poppins-Regular', sans-serif;
  letter-spacing: 0
}

body {
  font: 14px/17px 'Poppins-Regular', sans-serif;
  background: #E5E5E5
}

a {
  text-decoration: none;

  &:hover {
    color: var(--secondary-600);
  }
}

:focus {
  outline: none
}

.regular {
  font-family: 'Poppins-Regular', sans-serif
}

.bold {
  font-family: 'Poppins-Bold', sans-serif
}

.semiBold {
  font-family: 'Poppins-SemiBold', sans-serif
}

.light {
  font-family: 'Poppins-Light', sans-serif
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.02em
}

.pos_rel {
  position: relative
}

.pos_absol {
  position: absolute
}

.pos_fixed {
  position: fixed
}

.pwdval_cnt {
  ion-label {
    &.error {
      color: var(--error-500) !important;
    }
  }
}

.pwdval_cnt {
  ion-label {
    &.success {
      color: var(--success-600) !important;
    }
  }

}

/* Page Header section */
.user_sec {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  @include flexbox_column($fdir: column, $align-items: center);
  box-shadow: 0 1px 0 0 var(--gray-200);
  padding: 20px 0 15px;
  background: var(--white);

  .user_thumb {
    @include font-bold($fSize: 23px, $lHeight: 28px);
    font-weight: bold;
    width: 48px;
    height: 48px;
    background: var(--primary-500);
    border-radius: 48px;
    color: var(--white);
    @include flexbox_align($align-items: center, $just-cont: center)
  }

  .user_name {
    h1 {
      @include font-bold($fSize: 23px, $lHeight: 28px);
      color: var(--gray-900);
      margin-top: 22px;
      text-align: center;
      padding: 0 40px;
      word-break: break-word;
    }
  }

  .msge_pos {
    position: relative;
    width: 138px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    border-radius: 40px;
    padding-inline: 15px;

    &.ion-activated {
      background: transparent;
    }

    &.chip-outline.ion-color {
      border-color: rgba(var(--ion-color-base-rgb), 1);
    }

    ion-badge {
      @include font-light($fSize: 8px, $lHeight: 8px);
      width: 12px;
      height: 12px;
      padding: 2px 3px;
      position: absolute;
      top: 7px;
      right: 10px;
    }

    ion-label {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
    }
  }
}

/* Page Header section */
/* Common Page Header section */
ion-header {
  &.page_header {
    ion-toolbar {
      padding: 10px 13px 0 13px;
      //padding:39px 13px 0 13px;
      // --border-width: 0 0 2px!important;
      --border-width: 0 !important;
      --border-color: var(--gray-200);
      --background: var(--white);
      box-shadow: 0px 1px 0px var(--gray-200);

      ion-buttons {
        margin-inline-start: 0;
        margin-right: -8px;

        ion-button {
          @include font-regular($fSize: 16px, $lHeight: 24px);

          &.ion-activated {
            --color: var(--secondary-600);
            opacity: 1;
          }
        }
      }

      ion-back-button {
        font-size: inherit;
        --padding-start: 0;
        --padding-end: 0;
        min-width: 24px;
        height: auto;
      }

      ion-back-button {
        font-size: inherit;
      }

      ion-title {
        @include font-semibold($fSize: 16px, $lHeight: 24px);
        text-align: center;
        color: #1E293B;
        padding-inline: 30px;
      }
    }

    /* android styles */
    &.md {
      ion-toolbar {
        padding-top: 12px !important;
        --min-height: 46px;
      }
    }

    /* android styles */
  }
}

.page_header2 {
  ion-toolbar {
    --background: $white;
    --border-width: 0 0 2px !important;
    --border-color: var(--gray-200);
    background-color: $white;
    padding: 10px 11px 0 11px !important;

    .page_header2__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--white);

      button {
        background-color: var(--white);
        padding: 0;
      }

      .shared_link {
        width: 23px;
        height: 23px;
        background: url(/assets/icon/shared_link.svg);
      }
    }
  }

  /* android styles */
  &.md {
    ion-toolbar {
      padding-top: 12px !important;
    }
  }

  /* android styles */
}

/* Common Page Header section */
/* Modal Header section */
ion-header {
  &.hdr_modal {
    ion-toolbar {
      padding: 11px 4px 0;
      --border-width: 0 !important;
      box-shadow: 0 1px 0 var(--gray-200);
      --background: var(--white);

      ion-title {
        @include font-semibold($fSize: 16px, $lHeight: 24px);
        color: #1E293B;
      }

      .modal_done {
        font: 16px/24px "Poppins-Regular", sans-serif;
        @include font-regular($fSize: 16px, $lHeight: 24px);

        &.ion-activated {
          --color: var(--secondary-600);
          opacity: 1;
        }
      }

      ion-button {
        &.button-disabled {
          --color: var(--gray-400);
          opacity: 1;
        }
      }
    }
  }
}

.my-modal-styles {
  align-items: flex-end;
  --height: calc(100% - 20px);
  --border-radius: 10px 10px 0 0;
}

.my-sheet-modal {
  --border-radius: 32px;
  --max-height: 624px;
}

.my-sheet-modal_h330 {
  --border-radius: 32px;
  --max-height: 340px;
}

.my-sheet-modal_h404 {
  --border-radius: 32px;
  --max-height: 404px;
}

.my-sheet-modal_h432 {
  --border-radius: 32px;
  --max-height: 432px;
}

.my-sheet-modal_h482 {
  --border-radius: 32px;
  --max-height: 482px;
}

.my-sheet-modal_h516 {
  --border-radius: 32px;
  --max-height: 516px;
}

.my-sheet-modal_h700 {
  --border-radius: 32px;
  --max-height: 700px;
}

/* Modal Header section */
/* Main Tabs */
ion-tabs {
  ion-tab-bar {
    --border-width: 0;
    box-shadow: 0 -0.5px 0 rgba(0, 0, 0, 0.3);
    padding-top: 5px;
    padding-bottom: 28px;
    --background: var(--white)
  }

  ion-tab-button {
    --ripple-color: var(--white);

    ion-label {
      @include font-regular($fSize: 10px, $lHeight: 14px);
      color: var(--gray-500);
      padding-top: 4px;
    }

    &.tab-selected {
      ion-label {
        font-family: 'Poppins-SemiBold', sans-serif;
        color: #1D4ED8;
      }

      .mtab_icon {
        &.home_land {
          background-image: url(assets/icon/home_icon_activated.svg);
        }

        &.search {
          background-image: url(assets/icon/search_icon_activated.svg);
        }

        &.shortlist {
          background-image: url(assets/icon/shortlist_icon_activated.svg);
        }

        &.open_days {
          background-image: url(assets/icon/calendar-active.svg);
        }

        &.user_profile {
          background-image: url(assets/icon/profile_icon_activated.svg);
        }
      }
    }

    .mtab_icon {
      @include flexbox_align($align-items: center, $just-cont: center);
      width: 24px;
      height: 21px;
      background-repeat: no-repeat;
      background-position: center;

      &.home_land {
        background-image: url(assets/icon/home_icon.svg);
      }

      &.search {
        background-image: url(assets/icon/search_icon.svg);
      }

      &.shortlist {
        background-image: url(assets/icon/shortlist_icon.svg);
      }

      &.open_days {
        background-image: url(assets/icon/calendar-default.svg);
      }

      &.user_profile {
        background-image: url(assets/icon/profile_icon.svg);
      }
    }
  }
}

/* styes for android */
.md {
  ion-tabs {
    ion-tab-bar {
      padding-bottom: 5px;
    }

    ion-tab-button {
      ion-label {
        padding-top: 2px;
      }
    }
  }
}

/* styes for android */
/* Main Tabs */
ion-content {
  --background: #F8FAFC;
}

.skeleton_cnt {
  .skel_row {
    width: 100%;
    min-height: 52px;
    border-radius: 5px;
    @include flexbox($fdir: row, $just-cont: flex-start, $align-items: center);
    padding: 13px 16px;

    ion-img {
      width: 17px;
      margin-right: 12px;
    }

    ion-label {
      @include font-regular($fSize: 14px, $lHeight: 20px);
    }
  }

  .err_skeleton {
    padding: 34px 16px 0 16px;

    .skel_row {
      background: #FEF2F2;
      border: 1px solid var(--error-500);

      ion-label {
        color: var(--error-600);
      }
    }
  }

  .succ_skeleton {
    padding: 0 0 24px 0;

    .skel_row {
      background: var(--success-50);
      border: 1px solid var(--success-500);

      ion-label {
        color: var(--success-600);
      }
    }
  }
}

.otp_screen {
  .skeleton_cnt {
    .skel_row {
      ion-label {
        width: calc(100% - 29px);
      }
    }
  }
}

.subs_success {
  .skeleton_cnt {
    .skel_row {
      height: auto;
      align-items: flex-start;
      padding: 16px 16px 12px;

      ion-img {
        width: 28px;
      }

      ion-label {
        margin-top: -4px;
      }
    }
  }
}

.skel_pos {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.ygrd_ft {
  ion-toolbar {
    box-shadow: 0 -0.5px 0 rgb(0 0 0 / 30%);
    --border-width: 0 !important;
  }

  .btn {
    ion-button {
      &.button-disabled {
        --ion-color-base: var(--gray-200) !important;
        --ion-color-contrast: var(--gray-400) !important;
        opacity: 1;
      }

      &.ion-activated {
        --ion-color-shade: var(--secondary-600) !important;
      }
    }
  }
}

/* Form Styles */
.form_ui {
  .stacked_label {
    @include font-regular($fSize: 14px, $lHeight: 20px);
    color: var(--gray-600);
    margin-top: 0;
  }

  ion-text {
    h3 {
      @include font-bold($fSize: 28px, $lHeight: 34px);
      color: var(--gray-900);
      letter-spacing: -0.02em;
      margin-top: 0;
      margin-bottom: 26px;
    }
  }

  .fgt_pwd {
    @include font-regular($fSize: 14px, $lHeight: 20px);
    color: var(--gray-900);
    margin: 14px 0 22px;
  }

  ion-item {
    .field_row {
      width: 100%;

      ion-input {
        caret-color: var(--secondary-500);
        @include font-regular($fSize: 14px, $lHeight: 20px);
        color: var(--gray-400);
        border: 1px solid var(--gray-300);
        border-radius: 26px;
        --placeholder-opacity: 1;
        --padding-start: 15px;
        --padding-end: 15px;
        --padding-top: 11px;
        --padding-bottom: 11px;
        background: var(--white);

        &.has-focus,
        &.has-value {
          color: var(--black);
        }

        &.has-focus {
          border: 1px solid var(--secondary-500);
        }
      }
    }
  }
}

/* Form Styles */
/* Android Fix */
.header-md::after {
  background-image: none;
}

.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h,
.native-input[disabled].sc-ion-input-ios {
  opacity: 1;
  color: var(--gray-500);

  .input_label {
    color: var(--gray-500) !important;
  }
}

.item-input.sc-ion-label-ios-h,
.item-input .sc-ion-label-ios-h {
  max-width: inherit;
}

/* Android Fix */
.loader_btn {
  .spinner {
    background: url(assets/icon/spinner_icon.svg) no-repeat center center;
    background-size: 25px;
    width: 20px;
    height: 20px;
  }

  .btn_txt {
    display: none;
  }
}

.ftstky_btn {
  .loader_btn {
    .btn_txt {
      display: none !important;
    }
  }
}

.loading_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  @include flexbox_align($align-items: center, $just-cont: center);

  .spinner {
    background: url(assets/icon/spinner_icon.svg) no-repeat center center;
    background-size: 60px;
    width: 55px;
    height: 55px;
  }

  &.dis_page {
    pointer-events: initial;
    background: transparent;
  }
}

.ion-loading-class,
.custom-load-class {
  --background: var(--white);
  --spinner-color: var(--gray-900);
  color: var(--gray-900);
}

/* Interaction button */
.inter_btn {
  ion-button {
    &:nth-last-child(2) {
      margin-bottom: 8px;
    }
  }
}

.btn {
  .btn_txt {
    font-family: Poppins-SemiBold, sans-serif;
  }

  &.visit_web {
    @include font-semibold($fSize: 14px, $lHeight: 17px);
    width: 100%;
    height: 40px;
    margin: 0;
    text-transform: inherit;
    --border-radius: 24px;
    --box-shadow: none;
    --ion-color-contrast: var(--secondary-500) !important;
    --ion-color-base: var(--white) !important;
    --ion-color-shade: var(--secondary-600) !important;
    --border-width: 1px;
    --border-color: var(--secondary-500);
    --border-style: solid;

    &.ion-activated {
      --ion-color-base: var(--secondary-600) !important;
      --ion-color-shade: var(--secondary-600) !important;
      --border-color: var(--secondary-600);
      --ion-color-contrast: var(--white) !important;
    }
  }

  &.get_pros {
    @include font-semibold($fSize: 14px, $lHeight: 17px);

    span {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
    }

    width: 100%;
    height: 40px;
    margin: 0;
    text-transform: inherit;
    --border-radius: 24px;
    --box-shadow: none;
    --ion-color-contrast: var(--white) !important;
    --ion-color-base: var(--secondary-500) !important;
    --ion-color-shade: var(--secondary-600) !important;
    --border-width: 1px;
    --border-color: var(--secondary-500);
    --border-style: solid;

    &.ion-activated {
      --ion-color-base: var(--secondary-600) !important;
      --ion-color-shade: var(--secondary-600) !important;
      --border-color: var(--secondary-600);
      --ion-color-contrast: var(--white) !important;
    }

    .ordered_tick {
      display: none;
    }
  }

  &.req_info {
    @include font-semibold($fSize: 14px, $lHeight: 17px);

    span {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
    }

    width: 100%;
    height: 40px;
    margin: 0;
    text-transform: inherit;
    --border-radius: 24px;
    --box-shadow: none;
    --border-width: 1px;
    --border-style: solid;
  }

  &.subscribe,
  &.req_info {
    span {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
    }

    --ion-color-base: var(--success-600) !important;
    --border-color: var(--success-600);
    --ion-color-shade: var(--success-700) !important;
    --ion-color-tint:var(--success-700) !important;

    &.ion-activated {
      --ion-color-base: var(--success-700) !important;
      --ion-color-shade: var(--success-700) !important;
      --border-color: var(--success-700);
    }
  }

  &.subscribed,
  &.ordered {
    --ion-color-base: var(--gray-200) !important;
    --ion-color-contrast: var(--gray-400) !important;
    --border-color: var(--gray-200);
    opacity: 1;
    pointer-events: none;

    .ordered_tick {
      display: block;

      img {
        margin-right: 8px;
      }
    }

    .sub_scribed {
      img {
        margin-right: 8px;
      }
    }
  }

  &.ordered {
    --ion-color-base: var(--gray-200) !important;
    --ion-color-contrast: var(--gray-400) !important;
    --border-color: var(--gray-200);
    opacity: 1;
    pointer-events: none;
  }
}

/* Interaction button */
/* Footer sticky button*/
.ftstky_btn {
  .btn {
    @include font-semibold($fSize: 14px, $lHeight: 17px);
    --border-radius: 24px;
    width: 100%;
    height: 41px;
    margin: 0;
    --box-shadow: none;
    --ion-color-base: var(--success-600) !important;
    --ion-color-tint: var(--success-700) !important;

    &.ion-activated {
      --ion-color-shade: var(--success-700) !important;
    }

    &.button-disabled {
      --ion-color-base: var(--gray-200) !important;
      --ion-color-contrast: var(--gray-400) !important;
      opacity: 1;
    }
  }
}

/* Footer sticky button*/
/* Agreed checkbox */
.terms_cond {
  float: left;
  width: 100%;
  margin: 23px 0 30px;
  padding-left: 24px;

  input {
    display: none;

    &:checked {
      &+ {
        span {
          border: 2px solid var(--secondary-500);
          background: url(/assets/icon/check_tick.svg) no-repeat center center;
          background-size: 12px;
        }
      }
    }
  }

  .cond-checkbox {
    width: 15px;
    height: 15px;
    float: left;
    border-radius: 2px;
    border: 2px solid var(--gray-900);
    margin: 2px 0 0 -24px;
  }

  .condchk_txt {
    @include font-regular($fSize: 14px, $lHeight: 20px);
    color: var(--gray-900);
    vertical-align: top;
  }
}

/* Agreed checkbox */
/* Course Details Common styles */
.cdetails_hdr {

  ion-list,
  ion-item {
    background: transparent;
    --background: transparent;
  }

  .cdcomp_tit {
    h3 {
      @include font-semibold($fSize: 19px, $lHeight: 23px);
      letter-spacing: -0.02em;
      color: var(--gray-900);
    }
  }

  .cdcomp_note,
  .cd_val {
    @include font-semibold($fSize: 10px, $lHeight: 14px);
    margin-inline: 3px;
    letter-spacing: 0.08em;
    --ion-color-base: var(--gray-600) !important;
    --ion-color-shade: var(--gray-600) !important;
  }
}

.cdetails_drop {
  ion-list {
    ion-list-header {
      background: #F8FAFC;
      padding-inline-start: 0;

      ion-label {
        margin: 32px 0 30px 0;
        padding-left: 15px;

        h2 {
          @include font-bold($fSize: 28px, $lHeight: 34px);
          color: var(--black);
          max-width: 220px;
        }
      }
    }

    ion-item {
      --padding-start: 0;
      --inner-border-width: 0;
      --border-color: var(--gray-200);
      --border-width: 0;
      box-shadow: 0 1px 0 var(--gray-200);
      margin-bottom: 1px;
      --background: var(--white);

      ion-label {
        padding: 14px 16px 12px 0;
        margin: 0;

        span {
          font-size: 14px;
          line-height: 17px;
          color: var(--gray-900);
        }
      }

      &.item {
        ion-label {
          display: flex;
          flex-direction: column;
          text-overflow: inherit;
          white-space: normal;
          overflow: visible;

          .qual_subtit {
            @include font-regular($fSize: 14px, $lHeight: 20px);
            color: var(--gray-900);
            margin-top: 3px;
          }
        }
      }

      ion-icon {
        width: 22px;
        color: var(--gray-900);
      }
    }
  }
}

.course_details,
.uni_profile,
.quiz_results {
  .remv_togg {
    position: relative;
    max-height: 184px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(0deg, rgba(248, 250, 252, 1) 10%, rgba(255, 255, 255, 0) 100%);
      z-index: 0;
    }
  }

  .cdetails_descrip {
    padding: 0 16px;

    p,
    &>span {
      font: var(--font-size-secondary) var(--font-regular);
      color: var(--gray-600);
      margin: 16px 0 2px 0;
    }

    &>span {
      display: block;
    }

    ul,
    ol {
      padding: 0 16px;

      li {
        font: var(--font-size-secondary) var(--font-regular);
        color: var(--gray-600);
      }
    }

    h2,
    h3 {
      font: var(--font-size-sm-primary) var(--font-semibold);
    }

    h4,
    h5,
    h6,
    strong {
      font: var(--font-size-sm-primary) var(--font-semibold);
    }

    strong {
      display: block;
      margin: 16px 0 8px 0;
    }

    table {
      width: 100%;
      margin: 20px auto;
      border: 1px solid #e2e8f0 !important;
      border-bottom: 0;
      border-left: 0;

      th,
      td {
        padding: 16px 1%;
        border-left: 1px solid #e2e8f0 !important;
        border-bottom: 1px solid #e2e8f0 !important;
        vertical-align: top;
        font: var(--font-size-secondary) var(--font-regular);
        color: var(--gray-600);
      }
    }
  }

  .conteditor_cnt {
    .read_mre {
      padding: 0 16px;
      margin: 0 0 24px 0;

      a {
        font: var(--font-size-primary) var(--font-regular);
        color: var(--secondary-500);
        text-decoration: underline;
      }
    }
  }

  .cdetails_comp {
    ion-list {
      ion-item {
        --padding-start: 16px;
      }
    }

    .cdetails_data {
      .cdetdata_row {
        padding: 0 16px;

        &:first-child {
          margin: 18px 0 20px;
        }

        .qual_subtit {
          @include font-regular($fSize: 14px, $lHeight: 20px);
          color: var(--gray-600);
          padding: 4px 0 0;
        }
      }
    }

    .probar_cont {
      padding: 0 16px;
      margin: 17px 0 0;

      .probar_row {
        margin: 0 0 11px;

        .probar_head {
          @include flexbox_row($fdir: row, $just-cont: space-between);
          margin: 0 0 5px;

          .subj,
          .perc {
            color: var(--gray-600);
          }

          .subj {
            @include font-regular($fSize: 14px, $lHeight: 20px);

            &.txt_underline {
              text-decoration: underline;
            }
          }

          .perc {
            @include font-semibold($fSize: 14px, $lHeight: 17px);
          }
        }

        .progbar_buffer {
          position: relative;
          height: 4px;
          border-radius: 2px;
          background: var(--gray-300);

          .progress {
            background: var(--primary-500);
            position: absolute;
            top: -2px;
            left: 0;
            width: 10%;
            height: 8px;
            border-radius: 4px;
          }

          &.pbar_deg {
            height: 8px;
            background: var(--secondary-500);
            border-radius: 4px;

            .progress {
              top: 0;
              border-radius: 4px 0 0 4px;
            }
          }
        }

        &.stud_deg {
          margin: 0 0 17px;

          .subj {
            margin-right: 12px;
          }
        }
      }

      .winner_cat {
        margin: 11px 0 0;
      }
    }

    .sep_line {
      border-bottom: 1px solid #cfcfcf;
      margin: 21px auto;
      width: calc(100% - 32px);
    }
  }

  .crse_det {
    &.skeleton_cnt {
      position: absolute;
      top: 18px;
      padding: 0 16px;
      z-index: 1;

      .skel_row {
        height: auto;
        padding: 20px 20px 14px;
        align-items: flex-start;

        ion-label {
          margin: -5px 14px 0 0;
        }

        .succ_txt {
          display: flex;
          align-items: flex-start;

          ion-img {
            width: 24px;
          }
        }

        .succ_close {
          ion-img {
            width: 12px;
            margin: 0;
          }
        }
      }
    }
  }
}

.uni_profile {
  .overview {
    .cdetails_descrip {
      margin: 38px 0 0;

      .read_mre {
        margin: 10px 0 0;
      }
    }
  }
}

.uni_prof_cont {
  .cdetails_descrip {
    p {
      margin: 2px 0 16px 0
    }

    .read_mre {
      margin: 10px 0 24px 0;
    }
  }
}

.card_shadow {
  padding: 16px 16px 14px;
  margin: 24px 0 22px;
  box-shadow: 0 1px 0 var(--gray-200);
  border-radius: 4px;
  --background: var(--white);
}

/* Course Details Common styles */
/* Tooltip */
.tooltips {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);

  .alert-wrapper {
    border-radius: 5px;
    max-width: 342px;
    height: auto;
    min-height: 78px;
    padding: 16px;
  }

  .alert-head {
    padding: 0;

    h2 {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
      color: var(--gray-900);
      margin: 0 0 10px;
      text-align: left;

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        float: left;
        margin: -4px 8px 0 0;
        background: url(assets/icon/tooltip_icon.svg);
      }

    }
  }

  .alert-message {
    @include font-regular($fSize: 12px, $lHeight: 18px);
    color: var(--gray-600);
    padding: 0;
    text-align: left;

    a {
      @include font-regular($fSize: 12px, $lHeight: 18px);
      color: var(--gray-600);
      text-decoration: underline;
    }
  }

  .alert-button-group {
    display: none;
  }

  &.f5_tooltips {
    .alert-head {
      h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include font-semibold($fSize: 16px, $lHeight: 24px);

        &::before {
          width: 48px;
          height: 48px;
          margin: 10px 0 16px;
          background: url(assets/icon/f5_rank_icon.svg);
        }
      }
    }

    .alert-message {
      @include font-regular($fSize: 14px, $lHeight: 20px);
      color: var(--gray-500);
      text-align: center;
    }

    .alert-button-group {
      display: flex;

      .alert-button {
        @include font-semibold($fSize: 14px, $lHeight: 17px);
        color: var(--secondary-500);
        font-weight: 600;
        border: 0;
        height: 30px;
        margin: 16px 0 0;

        span {
          @include font-semibold($fSize: 14px, $lHeight: 17px);
        }
      }

    }
  }
}

/* Tooltip */
/* Search Section */
.filter_btn {
  left: 50%;
  transform: translate(-50%, -50%);
  margin-inline-start: 0 !important;

  ion-fab-button {
    width: 115px;
    height: 41px;
    --border-radius: 24px;
    --background: var(--success-600);
    --background-activated: var(--success-700);
    --box-shadow: 0 13px 37px rgba(0, 0, 0, 0.21);

    &.ion-activated {
      --transform: scale(1);
    }

    ion-label {
      .dot {
        width: 4px;
        height: 4px;
        background: var(--white);
        border-radius: 10px;
        margin: 0 5px;
      }
    }
  }

  ion-label {
    @include font-semibold($fSize: 14px, $lHeight: 24px);
    color: var(--white);
    @include flexbox_align($align-items: center, $just-cont: center);

    ion-img {
      margin-right: 8px;
    }
  }
}

/* Favourites Start*/
.compare_btn {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-inline-start: 0 !important;

  ion-fab-button {
    width: 138px;
    height: 41px;
    --border-radius: 24px;
    --background: var(--secondary-500);
    --background-activated: var(--secondary-600);
    --background-focused: var(--secondary-600);
    --background-hover: var(--secondary-600);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --box-shadow: 0 13px 37px rgba(0, 0, 0, 0.21);

    &.ion-activated {
      --transform: scale(1);
    }

    ion-label {
      .compare_icon {
        width: 16px;
        height: 16px;
        background: url(/assets/icon/compare_icon.svg) no-repeat center center;
        margin: 0 8px 0 0;
      }

      .dot {
        width: 4px;
        height: 4px;
        background: var(--white);
        border-radius: 10px;
        margin: 0 5px;
      }
    }

    &.button-disabled {
      --background: var(--gray-200);
      --background-activated: var(--gray-200);
      --background-focused: var(--gray-200);
      --background-hover: var(--gray-200);
      --background-activated-opacity: 1;
      --background-focused-opacity: 1;
      --background-hover-opacity: 1;
      --box-shadow: none;
      pointer-events: none;

      ion-label {
        color: var(--gray-400);

        .compare_icon {
          background: url(/assets/icon/compare_icon_disabled.svg) no-repeat center center;
        }
      }
    }
  }

  ion-label {
    @include font-semibold($fSize: 14px, $lHeight: 24px);
    color: var(--white);
    @include flexbox_align($align-items: center, $just-cont: center);

    ion-img {
      margin-right: 8px;
    }
  }
}

/* Favourites End*/
.md.filter_btn {
  bottom: 0;
}

.srch_pod {
  .srch_cnt {
    display: flex;
    justify-content: flex-start;

    ion-buttons {
      margin-right: 10px;
    }
  }

  .search_bar {
    width: calc(100% - 24px);
    margin: 0;

    input {
      @include font-regular($fSize: 14px, $lHeight: 20px);
      color: var(--gray-900);
      border: 1px solid var(--gray-300);
      padding: 0 8px 0 39px;
      width: 100%;
      height: 36px;
      border-radius: 40px;
      background: var(--white) url(./assets/icon/srch_icon.svg) no-repeat 12px center;

      &::-webkit-input-placeholder {
        color: var(--gray-400);
      }

      &::placeholder {
        color: var(--gray-400);
      }

      &:focus {
        border-color: $secondary-500;
      }
    }
  }

  .sr_tab {
    .ui_tabs_nav {
      width: 100%;
      margin: 12px 0 9px 0;
      padding: 0;
      white-space: nowrap;

      li {
        display: inline-block;
        margin: 0 6px 0 0;

        a {
          @include font-semibold($fSize: 14px, $lHeight: 17px);
          color: var(--gray-900);
          border: 1px solid var(--gray-900);
          box-sizing: border-box;
          border-radius: 25px;
          padding: 7px 16px;
          display: flex;
          background: var(--white);

          &.tab_highlight {
            background: var(--gray-900);
            color: var(--white);
          }

          .count {
            font: var(--font-size-xs-primary) var(--font-semibold);
            color: var(--success-800);
            width: 15px;
            height: 15px;
            background: var(--success-200);
            border-radius: 7px;
            margin-left: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.show_more_results {
  ion-button {
    width: 100%;
    height: 41px;
    --border-radius: 40px;
    margin: 0;
    @include font-semibold($fSize: 14px, $lHeight: 17px);
    --color: var(--secondary-500);
    --border-color: var(--secondary-500);

    &.ion-activated {
      --background-activated: var(--secondary-600);
      --background-focused: var(--secondary-600);
      --border-color: var(--secondary-600);
    }
  }
}

/* Search Section */
/* Provider Results */
.prov_results {
  .prslt_row {
    padding: 16px;
    border-top: 1px solid #e2e2e2;
    border-left: 3px solid var(--primary-500);

    .view_all_btn {
      width: 100%;
      height: 41px;
      --border-radius: 40px;
      margin: -6px 0;
      @include font-semibold($fSize: 14px, $lHeight: 17px);
      --color: var(--secondary-500);
      --border-color: var(--secondary-500);

      &.ion-activated {
        --background-activated: var(--secondary-600);
        --background-focused: var(--secondary-600);
        --border-color: var(--secondary-600);
      }
    }
  }

  .card_header {
    display: flex;
    justify-content: space-between;

    .card_title {
      width: calc(100% - 32px);

      h3 {
        @include font-semibold($fSize: 14px, $lHeight: 17px);
        color: var(--gray-900);
        margin: 0;
      }
    }

    .srch_shotlist {
      width: 20px;
      height: 20px;
      background: transparent url(/assets/icon/uniinfo_shortlist.svg) no-repeat center center;
      margin: 2px 0 0;
    }
  }

  .ucas_pts_pod {
    padding: 0 !important;
    padding-inline: 0 !important;
    margin: 8px 0 0;
  }
}

/* Provider Results */
/* Accordion section */
.crse_results {
  ion-accordion {
    display: flex;
    flex-direction: column-reverse;

    &.accordion-collapsed,
    &.accordion-collapsing {
      ion-label {
        &.view_more {
          display: block;
        }

        &.view_less {
          display: none !important;
        }
      }
    }

    &.accordion-expanded,
    &.accordion-expanding {
      ion-label {
        &.view_more {
          display: none !important;
        }

        &.view_less {
          display: block;
        }
      }
    }
  }

  ion-item {
    --padding-start: 16px;
    --padding-end: 4px;
    --inner-padding-top: 17px;
    --inner-padding-bottom: 4px;
    --min-height: auto;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    border-top: 1px solid var(--gray-200);

    ion-label {
      font: 14px/17px "Poppins-Regular", sans-serif !important;
      --color: var(--secondary-500) !important;
      text-decoration: underline;
      margin: 0 !important;
    }

    ion-icon {
      margin-inline: 0 !important;
      margin: 0 !important;
    }

    .ion-accordion-toggle-icon {
      font-size: 16px;
      color: var(--gray-600);
    }
  }
}

/* Accordion section */
/* recent searches */
.search-snippet,
.one-line-snippet {
  padding: 16px;
  border-top: 1px solid $gray-200;
}

.snippet-footer {
  margin-top: 4px;
  @include flexbox_align($align-items: center, $just-cont: space-between)
}

/* recent searches */
/* course snippet */
.one-line-snippet {
  @include flexbox_align($align-items: center, $just-cont: space-between);
  border-top: none;
  border-bottom: 1px solid $gray-200;
}

/* course snippet */
/* Search Section ends */
/* filter page */
.custom-accordian {
  border-bottom: 1px solid $neutral-200;

  ion-label.label {
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  ion-icon {
    font-size: 1.4rem;
    color: $gray-900;
  }
}

ion-item.custom-ion-item {
  --inner-border-width: 0;
  border-bottom: 1px solid $neutral-200;

  ion-label {
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  ion-icon {
    font-size: 1.4rem;
    color: $gray-900;
    position: relative;
    right: 0;
  }
}

/* filter page */
/* Skeleton style */
/* SR & PR skeleton */
.custom-skeleton {
  ion-skeleton-text {
    --background: var(--gray-250);
    border-radius: 24px;
    min-height: 10px;
  }

  .large_heading {
    width: 100%;

    ion-skeleton-text {
      min-height: 24px;
    }
  }

  .small_heading {
    width: 100%;

    ion-skeleton-text {
      min-height: 20px;
    }
  }

  .page_heading {
    width: 100%;

    ion-skeleton-text {
      min-height: 17px;
    }
  }

  .heading {
    width: 100%;

    ion-skeleton-text {
      min-height: 14px;
    }
  }

  .title {
    width: 100%;

    ion-skeleton-text {
      min-height: 12px;
    }
  }

  .descrip {
    width: 100%;

    ion-skeleton-text {
      min-height: 10px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    ion-skeleton-text {
      min-height: 14px;
    }
  }

  &.univ-results,
  &.crse_results {
    .srch_count {
      ion-skeleton-text {
        min-height: 17px;
      }
    }

    .srch_shotlist {
      background: transparent url(assets/icon/skeleton_shortlist.svg) center center !important;

      ion-skeleton-text {
        border-radius: inherit;
        background: transparent;
      }
    }

    ion-label,
    ion-text,
    ion-note {
      min-width: 100px;
    }

    .uni_logo {
      box-shadow: none !important;
      background: transparent !important;
      padding: 0 !important;

      ion-skeleton-text {
        border-radius: inherit;
      }
    }

    ion-item {
      .ion-accordion-toggle-icon {
        color: var(--gray-250);
      }

      &.custom-ion-item {
        ion-icon {
          color: var(--gray-250);
        }
      }
    }

    .prslt_row {
      border-top: 1px solid var(--gray-250);
      border-left: 3px solid var(--gray-250);
    }

    .vwuni_rev {
      .rat_val {
        min-width: 22px;
      }

      a {
        min-width: 82px;
      }

      ion-note {
        min-width: 66px;
        margin: 0 !important;
      }
    }

    ion-card {
      .card_slide {
        ion-skeleton-text {
          border-radius: 8px;
          min-height: 254px;
          margin: 0;
        }
      }
    }
  }

  .custom-accordian {
    pointer-events: none;

    ion-accordion {
      ion-item {
        pointer-events: none;

        ion-icon {
          color: var(--gray-250) !important;
        }
      }
    }
  }

  ion-item {
    ion-icon {
      color: var(--gray-250) !important;
    }
  }

  /* Your Grades */
  &.ygrd_sec {
    ion-list {
      ion-list-header {
        ion-label {
          padding: 0 16px;
        }

        h2 {
          max-width: 100% !important;
        }
      }

      ion-item {
        ion-icon {
          color: var(--gray-250) !important;
          --color: var(--gray-250) !important;
        }
      }
    }

    .fltr_levls {
      .grd_minmax {
        .grd_val_col {
          ion-label {
            background-image: none;
            background-color: var(--gray-250);
            border-radius: 40px;
          }

          .grd_value {
            width: 32px !important;
            margin: 4px 0 0 6px !important;
          }
        }
      }
    }

    .add_qualif {
      ion-card-title {
        width: 100%;
      }

      ion-button {
        min-width: 200px;
        --border-color: var(--gray-250);
        --color: var(--gray-250);
        --background-hover: transparent !important;

        &.ion-activated {
          --background-activated: transparent !important;
        }
      }
    }
  }

  &.qualif_lst {
    ion-list {
      padding: 0 24px;

      ion-item {
        --padding-start: 0 !important;

        ion-radio {
          --color-checked: var(--gray-250) !important;
          --color: var(--gray-250) !important;
          background: var(--gray-250);
          border-radius: 20px;
        }
      }
    }

    ion-list-header {
      padding-inline-start: 0;
    }
  }

  /* Your Grades */
  /* Recent Search */
  .search-snippet {
    .snippet-footer {

      .footer-cap,
      .caption {
        min-width: 120px;
      }
    }
  }

  .one-line-snippet {
    h3 {
      width: 70%;
    }

    .caption {
      width: 25%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .center-align {
    min-width: 200px;
    display: inline-block;
  }

  /* Recent Search */
  /* UCAS Tariff section */
  .ucas_tariff_sec {
    .uct_col {
      ion-label {
        &:first-child {
          min-width: 180px;
        }

        &:last-child {
          min-width: 130px;
          padding: 0 20px;
        }
      }
    }

    .credit_pts {
      ion-icon {
        color: var(--gray-250);
      }
    }
  }

  &.ygrd_sec {
    .ucas_tariff_sec {
      .uct_col {
        ion-label {
          display: inline-block;
          margin: 0 0 10px;
        }

        ion-input {
          --background: var(--gray-250) !important;
          border: 1px solid var(--gray-250) !important;
        }
      }
    }
  }

  /* UCAS Tariff section */
}

/* SR & PR Skeleton */
/* No results */
.srch_results,
.h100 {
  height: 100%;
}

/* No results */
/* default button */
button {
  &.cta {
    &.button-disabled {
      background-color: var(--gray-200);
      color: var(--gray-400);
      cursor: default;
      pointer-events: none;
    }
  }
}

/* default button */
/* title info component */
.title-info {
  @include flexbox_align($align-items: center, $just-cont: space-between);
  flex-wrap: wrap;
  padding: 16px;

  .title-info__title {
    margin: 0 8px 0 0;
    @include font-semibold($fSize: 19px, $lHeight: 23px);
    color: var(--gray-900);
    letter-spacing: .1px;

    &.small-title {
      @include font-semibold($fSize: 14px, $lHeight: 17px);
    }
  }

  .title-info__info {
    span {
      @include font-semibold($fSize: 10px, $lHeight: 14px);
      letter-spacing: 1px;
      color: var(--gray-600);
    }
  }
}

/* title info component */
/*custom form*/
.custom-form {
  ion-item {
    position: relative;
    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    --border-color: transparent;
    --highlight-color-focused: transparent;
    --background: transparent;

    &.disabled {
      pointer-events: none;

      ion-input {
        background: var(--gray-100);
        color: var(--gray-400);
      }
    }
  }

  ion-label {
    color: var(--gray-600) !important;
    font-family: "Poppins-Regular", sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 4px !important;
    transform: none !important;
  }

  ion-input {
    @include font-regular($fSize: 14px, $lHeight: 20px);
    color: var(--black);
    border: 1px solid var(--gray-300);
    border-radius: 26px;
    --placeholder-opacity: 1 !important;
    --padding-start: 15px !important;
    --padding-end: 15px !important;
    --padding-top: 11px !important;
    --padding-bottom: 11px !important;
    background: var(--white);
    margin-top: 4px;

    &.has-focus {
      border: 1px solid var(--secondary-500);
    }
  }
}

.form-field {
  ion-item::part(native) {
    --border-style: none;
    border: 1px solid $gray-300;
    border-radius: 22px;
  }

  ion-textarea {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-end: 0;
    --inner-padding-end: 0;
  }
}

/*custom form*/
/* aligning section center of the screen */
.place-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/* aligning section center of the screen */
/*sliding list*/
.sliding-list {
  ion-item::part(native) {
    padding: 0;
  }

  .university-icon {
    width: 64px;
    height: 64px;
    background: #e6e6e6;
    border-radius: 8px;

    img {
      width: 64px;
      background: $white;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
      border-radius: 8px;
      display: block;
      height: 64px;
      object-fit: contain;
    }
  }
}

/*sliding list*/
/* modal sm component */
.modal-sm::part(content) {
  border-radius: 34px 34px 0 0;
}

.my-sheet-modal::part(handle),
.modal-sm::part(handle),
.my-sheet-modal_h330::part(handle) {
  margin-top: 8px;
  background-color: $gray-700;
  width: 21px;
  height: 4px;
}

.modal-sm {
  --max-height: 520px;
}

.fav_noreqinfo_modal {
  --max-height: 264px;
}

.fav_reqinfo_modal {
  --max-height: 382px;
}

.sr_noreqinfo_modal {
  --max-height: 426px;
}

.sr_reqinfo_modal {
  --max-height: 534px;
}

.reqinfo_modal_h515 {
  --max-height: 515px;
}

.reqinfo_modal_h560 {
  --max-height: 560px;
}

.get_pros_modal {
  --max-height: 495px;
}

.get_pros_modal_h560 {
  --max-height: 560px;
}

.get_pros_modal_h600 {
  --max-height: 600px;
}

.sort-modal {
  app-subject-filter {
    ion-toolbar {
      padding-top: 24px !important;
    }
  }
}

/* modal sm component */
/*title logo component */
.title-logo {
  .title-logo__title {
    width: calc(100% - 102px);
  }
}

/* univ logo component - can be used as a individual component */
.univ-logo {
  width: 64px;
  height: 64px;
  box-shadow: 0 13px 37px rgb(0 0 0 / 21%);
  border-radius: 8px;
  padding: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}

/* univ logo component */
/*title logo component */
/* shortlist page skeleton */
.skeleton-avatar-wrapper {
  ion-skeleton-text {
    border-radius: 8px;
  }
}

/* shortlist page skeleton */
/* Swiper Common Styles */
.mySwiper {
  padding: 0 16px
}

.uniinfo_swiper {
  padding: 0
}

.uni_info .swiper-horizontal>.swiper-pagination-bullets,
.uni_info .swiper-pagination-bullets.swiper-pagination-horizontal,
.uni_info .swiper-pagination-custom,
.swiper-pagination-fraction {
  top: 56%;
  bottom: auto;
}

.uni_info .uni-info-slider .swiper-horizontal>.swiper-pagination-bullets,
.uni_info .uni-info-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.uni_info .uni-info-slider .swiper-pagination-custom,
.swiper-pagination-fraction {
  top: unset;
  bottom: 16px;
}

.uni_info .swiper .swiper-pagination-bullet-active {
  background: var(--white);
}

.uni_info .swiper-pagination-bullet:last-child,
.uni_info .swiper-pagination-bullet:first-child {
  transform: scale(.5);
}

.uni_info .swiper-pagination-bullet:nth-last-child(2),
.uni_info .swiper-pagination-bullet:nth-child(2) {
  transform: scale(.7);
}

.uni_info .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.uni_info .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 2px;
}

/* Swiper Common Styles */

/* progress bar component style */
.progress-bar {
  position: relative;
  background-color: var(--gray-300);
  height: 4px;
  border-radius: 2px;
  width: 100%;

  .progress-bar__progress {
    background-color: var(--primary-500);
    height: 8px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: -2px;
  }
}

/* progress bar component style */

/*divider*/
.divider {
  height: 1px;
  background-color: var(--gray-200);
}

/*divider*/
/* Gallery Slider */
.gallery_slider {
  .GallerySwiper {
    display: flex;
    height: calc(100% - 86px);
    margin: -27px 0;

    .swiper-pagination {
      width: 43px;
      height: 22px;
      background: #0F172A;
      border-radius: 4px;
      font: var(--font-size-xs-primary) var(--font-semibold);
      color: var(--white);
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      line-height: 22px;
      right: 16px;
      left: auto;
      top: 0;
    }
  }
}

/* Gallery Slider */

/* custom card*/
.custom-card {
  background-color: var(--white);
  box-shadow: 0 1px 0 var(--gray-200);
  border-radius: 4px;
}

/* custom card*/
/* Footer bg color */
.ftstky_cnt ion-toolbar {
  --background: var(--white);
}

/* Social login styles */
.wel_body_ui {
  .social_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;

    .or_label {
      font: 14px/20px var(--font-Regular);
      color: var(--gray-600);
      padding: 10px 0 0;
    }

    .btn {
      --border-color: var(--gray-900);
      --color: var(--gray-900);

      &.apple_btn {
        background: transparent url(/assets/icon/apple_social_icon.svg) no-repeat 8px center;
      }

      &.google_btn {
        background: transparent url(/assets/icon/google_social_icon.svg) no-repeat 8px center;
      }

      &.fbook_btn {
        background: transparent url(/assets/icon/facebook_social_icon.svg) no-repeat 8px center;
      }

      &.ion-activated {
        --background-activated: transparent !important;
        --color-activated: $gray-900;
      }
    }
  }
}

/* Social login styles */
/* Get prospectus search address pod styles */
.mapboxgl-ctrl-geocoder {
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  padding: 14px 16px 10px;
  box-shadow: 0 1px 0 #e2e8f0;
  position: relative;
  z-index: 1;

  &.mapboxgl-ctrl {
    svg {
      display: none;
    }

    .mapboxgl-ctrl-geocoder--input {
      font: 14px/20px var(--font-Regular);
      color: var(--gray-900);
      border: 1px solid var(--gray-300);
      padding: 1px 8px 0 39px;
      width: calc(100% - 74px);
      height: 36px;
      border-radius: 40px;
      background: var(--white) url(assets/icon/srch_icon.svg) no-repeat 12px center;

      &::-webkit-input-placeholder {
        color: var(--gray-400);
      }

      &::placeholder {
        color: var(--gray-400);
      }

      &:focus {
        border-color: $secondary-500;
      }

      &:focus~.mapboxgl-ctrl-geocoder--pin-right {
        .mapboxgl-ctrl-geocoder--button {
          background: transparent;
        }
      }
    }

    .mapboxgl-ctrl-geocoder--pin-right {
      position: absolute;
      top: 21px;
      right: 16px;

      .mapboxgl-ctrl-geocoder--button {
        background: transparent;
        display: block !important;

        &::after {
          content: "Cancel";
          font: 16px/24px "Poppins-Regular", sans-serif;
          color: #3460dc;
        }

        &:focus {
          color: #173EAD;
        }
      }
    }
  }
}

.suggestions-wrapper {
  width: calc(100% + 32px);
  margin: 0 0 0 -16px;

  .suggestions {
    padding: 0;
    margin: 10px 0 0;
    height: calc(100vh - 240px);
    overflow-y: scroll;

    li {
      list-style: none;
    }

    .mapboxgl-ctrl-geocoder--powered-by {
      display: none !important;
    }
  }
}

.addr_ftstky_btn {
  &::before {
    background-image: none !important;
  }

  .btn {
    text-transform: inherit;
  }

  ion-toolbar {
    text-align: center;
    box-shadow: none;
    padding: 0;
    --background: #fff;
    --border-width: 0.55px 0 0;
    --border-color: #CBD5E1;
    --padding-bottom: 30px;
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 8px;
  }
}

/* Get prospectus search address pod styles */
/* Animation CSS */
:root {
  --animate-duration: 0.6s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  // -webkit-animation-duration: 0.4s;
  // animation-duration: 0.4s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform;
  animation-timing-function: ease-out;
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  will-change: transform;
  animation-timing-function: ease-out;
}

/* Animation CSS */
/* link Disabled */
.vcode_resend {
  a {
    &.link_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

/* link Disabled */
/* Storyly Start */
.storylyWeb {
  margin: 11px 0 -16px;
  z-index: 1;
  display: block;
}

/* Storyly End */
/* Comparison table */
.compare_table_ui {
  .compare_table_scroll {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: block;
    transition: all 0.3s;
    padding: 32px 0 16px 16px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  }

  .comp_table_cont {
    width: 100%;
    white-space: nowrap;
    display: inline-table;
    padding: 0 16px 0 0;

    .compare_header,
    .compare_uni_info,
    .compare_crse_info {
      display: flex;
      flex-direction: row;

      ul {
        padding: 0;
        margin: 0;
        border: 1px solid var(--gray-100);
        border-right: 0;

        li {
          font: var(--font-size-xs-secondary) var(--font-regular);
          color: var(--gray-900);
          padding: 16px;
          text-align: center;
          list-style: none;
          width: 171px;

          .rating_ui,
          .rating {
            display: flex;
            align-items: center;
            justify-content: center;

            .rating {
              ion-img {
                margin: 0 3px 0 0;
              }
            }

            .badge {
              margin-left: 8px;
            }
          }
        }

        &:nth-child(odd) {
          background: var(--white);
        }

        &:nth-child(even) {
          background: var(--gray-50);
        }

        &:last-child {
          border-right: 1px solid var(--gray-100);
        }
      }
    }

    .compare_header {
      ul {
        li {
          position: relative;
          z-index: 0;
          height: 100%;
          text-align: left;

          .delete-icon {
            display: inline-block;
            position: absolute;
            z-index: 1;
            right: 8px;
            top: 8px;
          }
        }
      }

      .uni_addf5_sec {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .fav_edit {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .university-icon {
          width: 64px;
          height: 64px;
          background: #fff;
          filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
          border-radius: 8px;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        .university-icon,
        .edit-icon {
          img {
            display: block;
          }
        }
      }

      .uni_title {
        font: var(--font-size-xs-secondary) var(--font-semibold);
        white-space: normal;
      }

      .crse_title {
        font: var(--font-size-xs-secondary) var(--font-regular);
        white-space: normal;
        min-height: 37px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .add_course {
          font: var(--font-size-xs-secondary) var(--font-semibold);
          color: var(--secondary-500);
          display: block;

          &:hover {
            color: var(--secondary-600);
          }
        }
      }

      .final5_sec {
        margin: 6px 0 0;

        .f5_btn {
          .btn_col {
            width: 100%;

            ion-label,
            ion-checkbox {
              height: 34px;
            }
          }
        }
      }
    }


    .compare_uni_info,
    .compare_crse_info {
      ul {
        display: flex;
        flex-direction: column;

        li {
          &:first-child {
            padding: 35px 16px 16px;
          }
        }
      }

      .sticky_header {
        font: var(--font-size-xs-primary) var(--font-regular);
        text-transform: uppercase;
        font-weight: 600;
        color: var(--gray-600);
        letter-spacing: 0.08em;
        position: absolute;
        left: 22px;
        margin: -24px 0 0;
        text-decoration: underline;

        &::after {
          content: '';
          position: absolute;
          border-top: 1px solid var(--gray-300);
          width: 100vw;
          top: 6px;
          margin-left: 10px;
        }
      }
    }
  }

  .acc_content {
    display: flex;
    flex-direction: column;
  }

  .accordion_panel {
    display: flex;
    min-height: 34px;

    .accordion_tab {
      position: absolute;
      left: 16px;
      width: 100%;

      .tab_heading {
        font: var(--font-size-xs-secondary) var(--font-regular);
        text-transform: uppercase;
        font-weight: 600;
        color: var(--white);
        letter-spacing: 0.08em;
        display: flex;
        align-items: center;
        width: calc(100% - 32px);
        background: var(--gray-700);
        padding: 9px 16px;
        border: 1px solid rgb(148 163 184 / 50%);

        ion-icon {
          font-size: 17px;
          margin-right: 8px;
        }
      }
    }
  }

  .default_down {
    transform: rotate(0deg);
    transition: transform 0.2s;
  }

  .rotate {
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
}

/* Comparison table */
/* Add to final 5 Start */
.final5_sec {
  position: relative;

  .f5_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn_col {
      width: 100%;
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;

      a {
        font: var(--font-size-xs-secondary) var(--font-semibold);
        color: var(--secondary-500);
        display: block;

        &:hover {
          color: var(--secondary-600);
        }
      }
    }

    ion-checkbox {
      position: absolute;
      z-index: 3;
      margin: 0;
      width: 100%;
      height: 34px;
      --border-radius: 40px;
      --checkmark-width: 0;
      opacity: 0;
    }

    ion-label {
      font: var(--font-size-xs-secondary) var(--font-semibold);
      color: var(--secondary-500);
      width: 100%;
      height: 34px;
      border: 1px solid var(--secondary-500);
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .added_f5 {
      font: var(--font-size-xs-secondary) var(--font-semibold);
    }

    ion-button {
      &.button-disabled {
        ~.addtofinal5 {
          background: var(--gray-200) !important;
          border: 1px solid var(--gray-200);
          color: var(--gray-400) !important;
          opacity: 1;
          pointer-events: none;
        }
      }

      ~.addedtofinal5 {
        display: none;
      }

      &.loader_btn {
        ~ion-label {
          background: var(--success-600);
          border: 1px solid var(--success-600);
          color: var(--white);
        }

        ~.addtofinal5 {
          display: none;
        }

        ~.addedtofinal5 {
          display: flex;

          .spinner {
            background: url(assets/icon/spinner_icon.svg) no-repeat center center;
            background-size: 25px;
            width: 20px;
            height: 20px;
          }

          .added_f5 {
            display: none;
          }
        }
      }

      &.checkbox-checked {
        ~ion-label {
          background: var(--success-600);
          border: 1px solid var(--success-600);
          color: var(--white);

          img {
            width: 11px;
            margin-right: 8px;
          }
        }

        ~.addtofinal5 {
          display: none;
        }

        ~.addedtofinal5 {
          display: flex;

          .spinner {
            display: none;
          }

          .added_f5 {
            display: flex;
          }

        }
      }
    }
  }
}

/* Add to final 5 End */
/* Remove comparison Basket & Column */
.remove_comp_basket {
  .alert-wrapper {
    --min-width: 344px;

    .alert-head {
      padding-top: 23px;

      h2 {
        font: var(--font-size-md-primary) var(--font-semibold);
        color: var(--gray-900)
      }
    }
  }

  .alert-message {
    font: var(--font-size-secondary) var(--font-regular);
    color: var(--gray-700);
    padding-bottom: 30px;
  }

  .alert-button-group {
    padding: 0 16px 16px 0;

    .alert-button {
      font: var(--font-size-primary) var(--font-semibold);
      border-top: 0;
      border-right: 0;

      &:nth-child(2) {
        height: 41px;
        margin: 0;
        border-radius: 24px;
        background-color: var(--error-600);
        color: var(--white)
      }

      &.ion-activated {
        &:nth-child(2) {
          background-color: var(--error-700);
        }
      }
    }
  }
}

/* Remove comparison Basket & Column */
/* Choose option message alert */
.chse_diff_fav {
  padding: 0 0 16px 0;

  .skel_row {
    background: var(--secondary-50);
    border: 1px solid var(--secondary-500);
    justify-content: space-between;
    align-items: flex-start;

    ion-label {
      width: calc(100% - 37px);
      margin: 0 12px;
      color: var(--secondary-500);
    }

    ion-img {
      width: 21px;
      margin-right: 0;
    }
  }

  &.max_limit {
    .skel_row {
      align-items: center;

      ion-label {
        width: calc(100% - 28px);
        margin: 0 0 0 10px;
      }

      ion-img.close {
        display: none;
      }
    }

    &.orange {
      .skel_row {
        background: var(--warning-50);
        border: 1px solid var(--warning-600);

        ion-label {
          color: var(--warning-600);
        }
      }
    }

  }
}

.fav_univ {
  .chse_diff_fav {
    .skel_row {
      align-items: center;

      ion-label {
        width: calc(100% - 28px);
        margin: 0 0 0 10px;
      }

      ion-img.close {
        display: none;
      }
    }
  }
}

/* Choose option message alert */
/* No results found */
.no_result_found {
  display: flex;
  align-items: center;
  width: 100%;

  .cta {
    &.cta-outline {
      &.cta-outline-blue {
        &:hover {
          background-color: var(--secondary-600) !important;
          border-color: var(--secondary-600);
          color: var(--white);
        }
      }
    }
  }
}

/* Book Opendays You might like(Recommendations) */
.bookopd_ui {
  padding: 32px 0 20px 0;

  .skeleton_cnt {
    margin: -16px 16px 0 16px;

    .skel_row {
      align-items: flex-start;

      ion-img {
        width: 22px;
        margin: 6px 12px 0 0;
      }
    }
  }

  h4 {
    font: 28px/38px "Poppins-Bold", sans-serif;
    color: var(--gray-900);
    margin: 0;
    padding: 0 16px;
  }

  .bkopd_settings {
    padding: 0 16px;
    margin: 16px 0 0;

    .recommand_title {
      font: var(--font-size-xs-secondary) var(--font-regular);
      color: var(--gray-500);
      margin: 0 0 8px 0;
    }

    .open-days__button {

      .cta {
        padding: 10px 18px;
        width: 112px;

        &.cta-green {
          background: var(--success-700);
        }

        .event_add {
          display: flex;
          align-items: center;

          svg {
            margin: 0 7px 0 0;
          }
        }
      }
    }

    .welhd_ui {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      justify-items: center;
      text-align: center;
      padding: 0 0 32px;

      &.reqinfo_welui {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        justify-items: center;
        text-align: center;
        padding: 0 0 32px;
      }

      p {
        font: 14px/20px 'Poppins-Regular', sans-serif;
        color: #000;
        margin-top: 7px;
      }

      ion-text,
      p {
        max-width: 100%;
        text-align: left;
      }

      .welhd_col {
        &:nth-last-child(2) {
          width: calc(100% - 102px);
        }

        .uni_logo {
          width: 64px;
          height: 64px;
          box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
          border-radius: 8px;
          padding: 4px;
          background: #fff;

          img {
            width: 100%;
            height: 100%;
          }
        }

      }

      &.bookopd_card {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        justify-items: center;
        background: #fff;
        box-shadow: 0 1px 0 0 #E2E8F0;
        border-radius: 4px;
        padding: 16px;
        margin: 0 0 2px;

        ion-text {
          text-align: left !important;

          h3 {
            font: 28px/34px "Poppins-Bold", sans-serif;
            color: var(--gray-900);
            letter-spacing: -0.02em;
          }
        }

        .event_day {
          text-align: left;
          margin: 0;

          h4 {
            margin: 0 0 5px 0 !important;
            padding: 0;
          }

          .event_txt {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }

    .opd_view_more {
      text-align: center;
      margin: 10px 0 0;

      a {
        font: var(--font-size-secondary) var(--font-regular);
        color: var(--secondary-500);
      }

    }
  }
}

.custom-skeleton {
  .open-days__button {
    ion-skeleton-text {
      width: 112px;
      height: 40px;
      margin-left: 16px;
    }
  }
}

.open-days {
  .open-days__button {
    .cta {
      padding: 10px 18px;
      width: 100px;
      justify-content: center;

      &.cta-green {
        background: var(--success-700);
      }

      .event_add {
        display: flex;
        align-items: center;

        svg {
          margin: 0 7px 0 0;
        }
      }
    }
  }
}

/* Book Openday You might like(Recommendations) */
/* Book Opendy Sign in & YEAR of Entry */
.bkopd_wel {
  .form_ui {
    padding: 34px 15px 0 15px;
  }

  .wel_body_ui {
    background: transparent;
    border-radius: 32px 32px 0 0;
  }

  .bookopd_ui {
    .submit_btn {
      margin: 8px 0 0;
    }
  }

  .welhd_ui {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    justify-items: center;
    text-align: center;
    padding: 0 0 32px;

    &.reqinfo_welui {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      justify-items: center;
      text-align: center;
      padding: 0 0 32px;
    }

    p {
      font: 14px/20px 'Poppins-Regular';
      color: #000;
      margin-top: 7px;
    }

    ion-text,
    p {
      max-width: 100%;
      text-align: left;
    }

    .welhd_col {
      &:nth-last-child(2) {
        width: calc(100% - 102px);
      }

      .uni_logo {
        width: 64px;
        height: 64px;
        box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
        border-radius: 8px;
        padding: 4px;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  ion-col {
    --background: transparent;

    ion-list {
      margin-bottom: 8px;
      background: transparent;
    }
  }

  ion-item {
    position: relative;
    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    --border-color: transparent;
    --highlight-color-focused: transparent;
    --background: transparent;

    &.disabled {
      pointer-events: none;

      ion-input {
        background: #F1F5F9;
        color: #94A3B8;
      }
    }

    .pwd_valid {
      position: absolute;
      right: 11px;
      top: 11px;
      width: 19px;
    }
  }

  .err_msge {
    display: none;
  }

  .error {
    ion-input {
      border: 1px solid #EF4444;

      &.has-focus {
        border: 1px solid #3460DC;
      }
    }

    .err_msge {
      font: 12px/18px 'Poppins-Regular';
      color: #EF4444;
      margin-top: 4px;
      display: block;
    }
  }

  .btn {
    font: 14px/17px "Poppins-SemiBold";
    --ion-color-base: #059669 !important;
    --border-radius: 24px;
    width: 100%;
    height: 41px;
    margin: 0;

    &.button-disabled {
      --ion-color-base: #E2E8F0 !important;
      --ion-color-contrast: #94A3B8 !important;
      opacity: 1;
    }

    &.ion-activated {
      --ion-color-shade: #047857 !important;
    }
  }

  .skeleton_cnt {
    display: none;

    .err_skeleton {
      padding: 0 0 24px;
    }
  }
}

.bookopd_card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  justify-items: center;
  padding: 0 0 32px;
  font: 14px/17px "Poppins-SemiBold",
    sans-serif;

  ion-text {
    h3 {
      font: 28px/34px "Poppins-Bold", sans-serif;
      color: var(--gray-900);
      letter-spacing: -0.02em;
    }
  }

  .welhd_col {
    &:nth-last-child(2) {
      width: calc(100% - 102px);
    }
  }

  .uni_logo {
    width: 64px;
    height: 64px;
    box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
    border-radius: 8px;
    padding: 4px;
    background: #fff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .event_day {
    text-align: left;
    margin: 10px 0 0;

    h4 {
      font: 14px/17px "Poppins-SemiBold", sans-serif;
      margin-bottom: 3px;
    }

    .event_txt {
      font-size: 14px;
      line-height: 17px;
      padding: 0
    }
  }
}

/* Book Opendy Sign in & YEAR of Entry */
/* Book Opendy Settings */
.subs_success {
  .bookopd_ui {
    .bkopd_settings {
      .welhd_ui {
        &.bookopd_card {
          box-shadow: none;
        }
      }
    }
  }
}

/* Book Opendy Settings */