/* adding custom colors */
:root{
  --ion-color-green: #059669;
	--ion-color-green-rgb: 5,150,105;
	--ion-color-green-contrast: #ffffff;
	--ion-color-green-contrast-rgb: 255,255,255;
	--ion-color-green-shade: #04845c;
	--ion-color-green-tint: #1ea178;

  /*red*/
  --ion-color-red: #EF4444;
	--ion-color-red-rgb: 239,68,68;
	--ion-color-red-contrast: #ffffff;
	--ion-color-red-contrast-rgb: 255,255,255;
	--ion-color-red-shade: #d23c3c;
	--ion-color-red-tint: #f15757;

  /* blue */
  --ion-color-blue: #3460DC;
	--ion-color-blue-rgb: 52,96,220;
	--ion-color-blue-contrast: #ffffff;
	--ion-color-blue-contrast-rgb: 255,255,255;
	--ion-color-blue-shade: #2e54c2;
	--ion-color-blue-tint: #4870e0;

  /* shades */
  --white: #fff;
  --black: #000;
  /* shades */

  /* gray / Neutral */
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-250: #e6e6e6;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-900: #0f172a;

  /* primary */
 
  --primary-300: #87E0FF;
  --primary-400: #54D2FF;
  --primary-500: #00BBFD;
  --primary-600: #00A4DD;
  --primary-700: #00759E;

  /* secondary */
  --secondary-50: #f1f4fd;
  --secondary-100: #e4eafa;
  --secondary-500: #3460dc;
  --secondary-600: #173ead;
  --secondary-700: #112f82;

  /*success*/
  --success-50: #ecfdf5;
  --success-100: #d1fae5;
  --success-200: #a7f3d0;
  --success-300: #6ee7b7;
  --success-400: #34d399;
  --success-500: #10b981;
  --success-600: #059669;
  --success-700: #047857;
  --success-800: #065f46;

  /* warning */
  --warning-50: #fffbeb;
  --warning-100: #fef3c7;
  --warning-200: #fde68a;
  --warning-300: #fcd34d;
  --warning-400: #fbbf24;
  --warning-550: #d97706;
  --warning-500: #f59e0b;
  --warning-600: #d97706;
  --warning-700: #b45309;
  --warning-800: #92400e;
  --warning-900: #78350f;


  /* error */
  --error-50: #fef2f2;
  --error-100: #fee2e2;
  --error-200: #fecaca;
  --error-300: #f87171;
  --error-400: #f87171;
  --error-500: #ef4444;
  --error-600: #dc2626;
  --error-700: #b91c1c;
  --error-800: #991b1b;
  --error-900: #7f1d1d;
}

/* making a loop to add custom colors using scss variable */
$customColors: ('green', 'red', 'blue');
@each $color in $customColors {
.ion-color-#{$color} {
--ion-color-base: var(--ion-color-#{$color}) !important;
--ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
--ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
--ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
--ion-color-shade: var(--ion-color-#{$color}-shade) !important;
--ion-color-tint: var(--ion-color-#{$color}-tint) !important;
}
}
/* making a loop to add custom colors using scss variable */

/*scss color variables*/

/* shades */
$white: #fff;
$black: #000;
/* shades */

/* primary */
$primary-50: #eefbff;
$primary-100: #ddf6ff;
$primary-200: #baedff;
$primary-300: #87e0ff;
$primary-400: #54d2ff;
$primary-500: #00bbfd;
$primary-600: #00a4dd;
$primary-700: #00759e;
$primary-800: #00465f;
$primary-900: #001720;
/* primary */

/* secondary */
$secondary-50: #f1f4fd;
$secondary-100: #e4eafa;
$secondary-200: #c9d5f6;
$secondary-300: #a0b5ef;
$secondary-400: #6a8ae5;
$secondary-500: #3460dc;
$secondary-600: #173ead;
$secondary-700: #112f82;
$secondary-800: #0c1f56;
$secondary-900: #06102b;
/* secondary */

/*success*/
$success-50: #ecfdf5;
$success-100: #d1fae5;
$success-200: #a7f3d0;
$success-300: #6ee7b7;
$success-400: #34d399;
$success-500: #10b981;
$success-600: #059669;
$success-700: #047857;
$success-800: #065f46;
$success-900: #06102b;
/*success*/

/* gray / Neutral */
$gray-50: #f8fafc;
$gray-100: #f1f5f9;
$gray-200: #e2e8f0;
$gray-300: #cbd5e1;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #334155;
$gray-800: #1e293b;
$gray-900: #0f172a;

/*Neutral*/
$neutral-50: #f8fafc;
$neutral-200: #e2e8f0;
/*Neutral*/
/* gray / Neutral */

/* warning */
$warning-50: #fffbeb;
$warning-100: #fef3c7;
$warning-200: #fde68a;
$warning-300: #fcd34d;
$warning-400: #fbbf24;
$warning-500: #f59e0b;
$warning-600: #d97706;
$warning-700: #b45309;
$warning-800: #92400e;
$warning-900: #78350f;
/* warning */

/* error */
$error-50: #fef2f2;
$error-100: #fee2e2;
$error-200: #fecaca;
$error-300: #f87171;
$error-400: #f87171;
$error-500: #ef4444;
$error-600: #dc2626;
$error-700: #b91c1c;
$error-800: #991b1b;
$error-900: #7f1d1d;
/* error */

/* dark mode */
$dark-50: #5f626b;
$dark-100: #555962;
$dark-200: #4c4f59;
$dark-300: #424651;
$dark-400: #424651;
$dark-500: #393d48;
$dark-600: #2f343f;
$dark-700: #262a36;
$dark-800: #1c212e;
$dark-900: #131825;
/* dark mode */

/*scss color variables */

@media (prefers-color-scheme: dark) {
  /*scss color variables */

  /* shades */
  $white: #fff;
  $black: #000;
  /* shades */

  /* primary */
  $primary-50: #EEFBFF;
  $primary-100: #DDF6FF;
  $primary-200: #BAEDFF;
  $primary-300: #87E0FF;
  $primary-400: #54D2FF;
  $primary-500: #00BBFD;
  $primary-600: #00A4DD;
  $primary-700: #00759E;
  $primary-800: #00465F;
  $primary-900: #001720;
  /* primary */

  /* secondary */
  $secondary-50: #F1F4FD;
  $secondary-100: #E4EAFA;
  $secondary-200: #C9D5F6;
  $secondary-300: #A0B5EF;
  $secondary-400: #3460DC;
  $secondary-500: #3460DC;
  $secondary-600: #173EAD;
  $secondary-700: #112F82;
  $secondary-800: #0C1F56;
  $secondary-900: #06102B;
  /* secondary */

  /*success*/
  $success-50: #ECFDF5;
  $success-100: #D1FAE5;
  $success-200: #A7F3D0;
  $success-300: #6EE7B7;
  $success-400: #34D399;
  $success-500: #10B981;
  $success-600: #059669;
  $success-700: #047857;
  $success-800: #065F46;
  $success-900: #064E3B;
  /*success*/

  /* gray / Neutral */
  $gray-50: #F8FAFC;
  $gray-100: #F1F5F9;
  $gray-200: #E2E8F0;
  $gray-300: #CBD5E1;
  $gray-400: #94A3B8;
  $gray-500: #FFFFFF;
  $gray-600: #475569;
  $gray-700: #334155;
  $gray-800: #1E293B;
  $gray-900: #0F172A;

  /*Neutral*/
  $neutral-50: #f8fafc;
  $neutral-200: #e2e8f0;
  /*Neutral*/
  /* gray / Neutral */

  /* warning */
  $warning-50: #fffbeb;
  $warning-100: #fef3c7;
  $warning-200: #fde68a;
  $warning-300: #fcd34d;
  $warning-400: #fbbf24;
  $warning-500: #f59e0b;
  $warning-600: #d97706;
  $warning-700: #b45309;
  $warning-800: #92400e;
  $warning-900: #78350f;
  /* warning */

  /* error */
  $error-50: #fef2f2;
  $error-100: #fee2e2;
  $error-200: #fecaca;
  $error-300: #f87171;
  $error-400: #f87171;
  $error-500: #ef4444;
  $error-600: #dc2626;
  $error-700: #b91c1c;
  $error-800: #991b1b;
  $error-900: #7f1d1d;
  /* error */

  /* dark mode */
  $dark-50: #5f626b;
  $dark-100: #555962;
  $dark-200: #4c4f59;
  $dark-300: #424651;
  $dark-400: #424651;
  $dark-500: #393d48;
  $dark-600: #2f343f;
  $dark-700: #262a36;
  $dark-800: #1c212e;
  $dark-900: #131825;
  /* dark mode */

  /*scss color variables */
}
