/* Common Variables */
$width: 1.0;
/* Common Variables */
/* Common utilities */
@mixin font-regular($fSize,$lHeight){
    font: $fSize+/+$lHeight "Poppins-Regular", sans-serif;
}
@mixin font-semibold($fSize,$lHeight){
    font: $fSize+/+$lHeight "Poppins-SemiBold", sans-serif;
}
@mixin font-bold($fSize,$lHeight){
    font: $fSize+/+$lHeight "Poppins-Bold", sans-serif;
}
@mixin font-light($fSize,$lHeight){
    font: $fSize+/+$lHeight "Poppins-Light", sans-serif;
}
@mixin flexbox($fdir,$just-cont,$align-items){
    display: flex;
    flex-direction: $fdir;
    justify-content: $just-cont;
    align-items: $align-items;
}
@mixin flexbox_column($fdir,$align-items){
    display: flex;
    flex-direction: $fdir;
    align-items: $align-items;
}
@mixin flexbox_row($fdir,$just-cont){
    display: flex;
    flex-direction: $fdir;
    justify-content: $just-cont;
}
@mixin flexbox_align($align-items,$just-cont){
    display: flex;
    align-items: $align-items;
    justify-content: $just-cont;
}
@mixin padding($top,$right,$bottom,$left){
    padding:$top $right $bottom $left;
}
@mixin margin($top,$right,$bottom,$left){
    padding:$top $right $bottom $left;
}
/* Common utilities */
