/* custom radio */
.custom-radio{
    ion-item{
        --inner-border-width: 0;
        --min-height: auto;
        font-size: 14px;
        line-height: 20px;
        color: $gray-900;
        margin: 16px 0;
        ion-radio{
            width: 16px;
            height: 16px;
            margin: 0 10px 0 0;
            --border-color: $gray-900;
        }
        ion-checkbox{
            width: 15px;
            height: 15px;
            margin: 0 10px 0 0;
            --border-color: $gray-900;
        }
        ion-label{
            margin: 0;
        }
    }
}
/* custom radio */