/* paddings */
.p-16 {
  padding: 16px;
}
.px24{
  padding-left: 24px;
  padding-right: 24px;
}
.pt8{
  padding-top: 8px;
}
.pt10{
  padding-top: 0;
}
.pb10{
  padding-bottom: 0;
}
.pr0{
  padding-right: 0;
}
.pt32{
  padding-top: 32px;
}
.pb34{
  padding-bottom: 34px;
}
/*forced paddings */
.force-pt-0{
  padding-top: 0 !important;
}
.force-pb-8{
  padding-bottom: 8px !important;
}
/*forced paddings */
/* paddings ends */

/* margins */
.m0 {
  margin: 0;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.mb4{
  margin-bottom: 4px;
}
.mb5{
  margin-bottom: 5px;
}
.my8{
  margin-top: 8px;
  margin-bottom: 8px;
}
.my24{
  margin-top: 24px;
  margin-bottom: 24px;
}
.my32{
  margin-top: 32px;
  margin-bottom: 32px;
}

.mb3 {
  margin-bottom: 3px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb6 {
  margin-bottom: 6px;
}

.mb8 {
  margin-bottom: 8px;
}
.mb10{
  margin-bottom: 10px;
}
.mb12{
  margin-bottom: 12px;
}
.mb16{
  margin-bottom: 16px;
}
.mb20{
  margin-bottom: 20px;
}
.mb24{
  margin-bottom: 24px;
}
.mb32{
  margin-bottom: 32px;
}
.mb40{
  margin-bottom: 40px;
}
.mt0{
  margin-top: 0;
}
.mt4{
  margin-top: 4px;
}
.mt12 {
  margin-top: 12px;
}
.mt10 {
  margin-top: 10px;
}
.mt16 {
  margin-top: 16px;
}
.mt20{
  margin-top: 20px;
}
.mt24{
  margin-top: 24px;
}
.mt30{
  margin-top: 30px;
}
.mt32{
  margin-top: 32px;
}
.mt34{
  margin-top: 34px;
}
.mt36{
  margin-top: 36px;
}
.mr5{
  margin-right: 5px;
}
.mr8{
  margin-right: 8px;
}
.mr16{
  margin-right: 16px;
}
.mr32{
  margin-right: 32px;
}
.ml10{
  margin-left: 10px;
}
.ml16{
  margin-left: 16px;
}
.force-mr16{
  margin-right: 16px !important;
}
.ml3{
  margin-left: 3px;
}
.ml4{
  margin-left: 4px;
}
.ml6{
  margin-left: 6px;
}
.ml8{
  margin-left: 8px;
}
/* forced margins */
.force-mt0{
  margin-top: 0 !important;
}
/* forced margins */
/* margins ends */

/*display props*/
/*flex props*/
.d-flex{
  display: flex;
}
.flex-wrap{
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.align-items-baseline{
  align-items: baseline;
}
.gapY-8{
  gap: 8px 0;
}
/*flex props*/
.force-d-block{
  display: block !important;
}
/*display props*/

/* transitions */
.transition{
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
/* transitions */

.text-center{
  text-align: center;
}
.visibility-hidden{
  visibility: hidden;
}

/* backgrounds */
.bg-neutral50{
  background-color: $neutral-50;
}
/* backgrounds */

/*width*/
.w-100{
  width: 100%;
}
/*width*/

/*spacers*/
.spacer-20{
  height: 20px;
}
.spacer-40{
  height: 40px;
}
.spacer-50{
  height: 50px;
}
.spacer-92{
  height: 92px;
}
/*spacers*/

/*tag*/
.tag{
  display: inline-block;
  font-family: "Poppins-SemiBold", sans-serif;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  background-color: $gray-100;
  color: $gray-500;
}
/*tag*/
